<template>
  <div class="price" v-scrollanimation>
    <div class="container">
      <div class="price__inner">
        <h2 class="price__title headline-2 w700 left-to-right">Цены</h2>
        <div class="price__wrap">
          <div
            class="price__card flex flex-column align-items-center bottom-to-top"
            v-for="(price, index) in priceList"
            :class="`delay-${index + 3}`"
            :key="index"
          >
            <h4 class="headline-5">{{ price.site_type }}</h4>
            <h3 class="w400 flex">
              <span class="headline-6">ОТ</span><span>{{ price.price }}</span
              ><span class="headline-6">Сум</span>
            </h3>
            <p class="headline-6 w400">Разработка за {{ price.days }} дней</p>
            <button class="headline-6 w400" @click="openSendModal()">
              Заказать
            </button>
            <ul class="w400">
              <li
                class="headline-6 bottom-to-top"
                :class="`delay-${listIndex + index + 3}`"
                v-for="(item, listIndex) in price.list"
                :key="item"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";

const priceList = [
  {
    site_type: "Посадочная страница",
    price: "1 350 000",
    days: "7",
    list: [
      "3 Варианта дизайна",
      "Хостинг на 3 месяца",
      "Обслуживание 3 месяца",
      "CЕО продвижение",
      "Комплексный подход",
    ],
  },
  {
    site_type: "Сайт Бизнес",
    price: "1 950 000",
    days: "7",
    list: [
      "3 Варианта дизайна",
      "Хостинг на 3 месяца",
      "Обслуживание 3 месяца",
      "CЕО продвижение",
      "Комплексный подход",
    ],
  },
  {
    site_type: "Интернет магазин",
    price: "3 750 000",
    days: "7",
    list: [
      "3 Варианта дизайна",
      "Хостинг на 3 месяца",
      "Обслуживание 3 месяца",
      "CЕО продвижение",
      "Комплексный подход",
    ],
  },
  {
    site_type: "Эксклюзивные сайты",
    price: "7 000 000",
    days: "20",
    list: [
      "Эксклюзивный дизайна",
      "Хостинг на 1 год",
      "Обслуживание 1 год",
      "CЕО продвижение",
      "Индивидуальный подход",
    ],
  },
];

const store = useStore();

const openSendModal = () => {
  store.commit("setSendModal", true);
};
</script>
<style lang="scss">
.price {
  height: 100%;
  position: relative;
  overflow: hidden;

  &__inner {
    padding: 5rem 0;
    padding-bottom: 7.5rem;
  }

  &__title {
    margin-bottom: 3.13rem;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.63rem;
  }

  &__card {
    background: var(--blue-1);
    max-width: 18.75rem;
    padding: 1.88rem 0.5rem;
    text-align: center;
    border-left: 0.06rem solid rgba(228, 215, 207, 0.2);
    border-radius: 1.25rem;

    button {
      background: var(--secondary-1);
      color: var(--white);
      padding: 0.38rem 1.5rem;
      border-radius: 3.13rem;
      margin-bottom: 2.19rem;
      margin-top: 1rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1);
        background: var(--primary-2);
      }
    }

    h4 {
      margin-bottom: 0.63rem;
    }

    p {
      color: var(--primary-2);
    }

    h3 {
      margin-bottom: 0.6rem;
      span {
        &:nth-child(1) {
          line-height: 160%;
          align-self: flex-end;
        }
        &:nth-child(2) {
          font-size: 2.38em;
          line-height: 3.56rem;
          letter-spacing: -2.03px;
          line-height: 130%;
          margin: 0 0.5rem;
        }
      }
    }

    ul {
      list-style: none;
      text-align: center;

      li {
        margin-bottom: 0.94rem;
      }
    }
  }
}

@media screen and (max-width: 1336px) {
  .price {
    font-size: 12px;

    &__inner {
      padding: 5rem 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .price {
    &__title {
      text-align: center;
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
    }

    &__card {
      flex: 0 0 30%;
      min-width: 20rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .price {
    font-size: 11px;

    &__inner {
      padding: 3rem 0;
    }

    &__card {
      width: 100%;
      flex: auto;
    }
  }
}
</style>
